import './App.css';

function App() {
  return (
    <div className="App">
      <div className="Top-Image">
        <div className="Text-Container">
        <h1>
          St Hilda's Ball 2022
        </h1>
        <a href="https://bookoxex.com/Go/AMidsummerNightsDream" target="_blank" rel="noreferrer noopener" class="button">Tickets</a>
        <a href="https://www.instagram.com/sthildasball2022/" target="_blank" rel="noreferrer noopener" class="button">Instagram</a>
        <a href="#theme" class="button">Theme</a>
        </div>
      </div>
      <div className="Bottom-Image" id="theme">
      <div className="Text-Container">
        <h1>
          A Midsummer Night's Dream
        </h1>
        </div>
      </div>
    </div>
  );
}

export default App;
